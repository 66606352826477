import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import background from './img/bg.jpg';
import zoom from './img/zoom.jpg';

function App() {
  return (
    <>
    <Row className='g-0'>
        <Col md={6}>
            <Image fluid src={background} />
        </Col>
        <Col md={6}>
            <Image fluid src={zoom} />
        </Col>
    </Row>

    <div className='welcome p-3 d-flex justify-content-center align-items-center'>
            <p className='fs-4 text-center'>
                今天是我們結婚的日子，十分高興能和你們一起分享喜悅。
                <br /><br />
                身在海外的親友，也希望透過 Zoom 視像，你也可以和我們一同見證我們共諧連理的一刻。
                <br /><br />
                謝謝你們體諒場地空間有限，未能邀請全部的親友。
                
            </p>
    </div>

    <Container className='mb-5'>    
        <Row className='m-4'>
            <Col md={{span:6, offset:3}}>
                <div className="d-grid gap-2">
                    <Button variant="success" size="lg"
                    onClick={()=> window.open("https://us02web.zoom.us/j/89259206992?pwd=UlVDOHlEeGFmMzY5ZzVlT0VaY094Zz09", '_blank', 'noopener,noreferrer')}>
                    按此使用 Zoom 觀看直播
                    </Button>
                </div>
            </Col>
        </Row>
        <Row className='m-4'>
            <Col md={{span:6, offset:3}}>
                <div className="d-grid gap-2">
                    <Button variant="outline-success" size="lg"
                    onClick={()=> window.open("https://www.youtube.com/watch?v=gW8aifVAfng", '_blank', 'noopener,noreferrer')}>
                    如何使用 Zoom
                    </Button>
                </div>
            </Col>
        </Row>
    </Container> 
    
    </>
  );
}

export default App;
